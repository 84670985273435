import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

export default function business() {

  const caseSwiper = document.getElementById('caseSwiper')
  if(caseSwiper){
    const swiperParams = {
      slidesPerView: 1,
      spaceBetween: 1,
      speed: 800,
      centeredSlides: false,
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        }
      }
    }
    const swiper = new Swiper(caseSwiper, swiperParams)
  }

}
