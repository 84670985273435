import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

export default function recruit() {
  const recruitSwiper = document.getElementById('recruitSwiper')
  if(recruitSwiper){
    const swiperParams = {
      slidesPerView: 'auto',
      spaceBetween: 10,
      speed: 4000,
      centeredSlides: false,
      loop: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
    }
    const swiper = new Swiper(recruitSwiper, swiperParams)
  }
}
