import * as imagesLoaded from 'imagesloaded'
import GsapSmoothScroll from './class/GsapSmoothScroll'
import gsap from 'gsap'
import { ScrollTrigger , ScrollToPlugin } from 'gsap/all'

import common from './common'
import index from './index'
import object from './object'
import business from './business'
import recruit from './recruit'
import usevue from './usevue'

gsap.registerPlugin(ScrollToPlugin)

document.addEventListener('DOMContentLoaded', function() {
  imagesLoaded(document.getElementById('app'), ()=>{
    //usevue()
    common()
    object()
    index()
    business()
    recruit()
    
    let smoother
    //smoother = new GsapSmoothScroll("#scrollArea", null, 1.6)
    setProp()

    function setProp(){
      if(smoother != null){
        document.documentElement.style.setProperty('--screen', `${smoother.getOffsets()}px`)
      }else{
        cancelAnimationFrame(setProp)
      }
      requestAnimationFrame(setProp)
    }

    setTimeout(()=>{
      smoothScrollToTargetIfHashExists()
    },800)

  })
});

function smoothScrollToTargetIfHashExists() {
  const hash = window.location.hash;
  
  if (hash && hash !== "#") {
    const targetElement = document.getElementById(hash.split("#")[1]);
    if (targetElement) {
      scrollToAnimated(targetElement)
    }
  }
}

function scrollToAnimated(targetElement) {
  const headerHeight = document.getElementById('site-header').offsetHeight;
  const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - headerHeight;
  gsap.to(window, { duration: 0.8, scrollTo: targetPosition });
}



