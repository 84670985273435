import * as Rellax from 'rellax'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'

gsap.registerPlugin(ScrollTrigger);

export default function common() {
  if(document.getElementsByClassName('rellax').length > 0){
    const rellax = new Rellax('.rellax',{
      center:true
    });
  }

  if(document.getElementsByClassName('rellax-no').length > 0){
    const rellax_no = new Rellax('.rellax-no',{
      center:false
    })
  }

  const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
    header: '#site-header',
    offset: 0,
    speed: 1000,
    easing: 'easeInOutQuart',
    speedAsDuration: true,
    durationMin: 1000
  })

  gsap.utils.toArray('.toAnimation').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top center+=15%",
      end: "bottom center",
      toggleClass: { targets: el, className: "active" },
      once: true,
      //markers: true,
    });
  })

  gsap.utils.toArray('.toAnimationAll').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top center+=15%",
      end: "bottom center",
      toggleClass: { targets: el, className: "active" },
      once: true,
      //markers: true,
    });
  })

  gsap.utils.toArray('.toPallax').forEach((el, index) => {
    gsap.fromTo(el,
      {
        y: 0,
      },
      {
        y: '-50%',
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom top",
          scrub: 2,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  })
  gsap.utils.toArray('.toPallaxRvs').forEach((el, index) => {
    gsap.fromTo(el,
      {
        y: 0,
      },
      {
        y: '50%',
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom top",
          scrub: 2,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  })

  let scrubs = 0
  if(window.innerWidth > 1119){
    scrubs = 2
  }
  gsap.utils.toArray('.toPallaxTop').forEach((el, index) => {
    gsap.fromTo(el,
      {
        y: 0,
      },
      {
        y: '50%',
        scrollTrigger: {
          trigger: el,
          start: "top top",
          end: "bottom top",
          scrub: scrubs,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  })


  /*
  const siteHeader = document.getElementById('site-header')
  if(siteHeader){
    gsap.fromTo(siteHeader,
      {},
      {
        background: 'rgba(255,255,255,.8)',
        scrollTrigger: {
          trigger: '#app',
          start: "top top",
          end: "top top-=50%",
          scrub: 1,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  }

  gsap.utils.toArray(".defaultScrollAnimation").forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top bottom",
      end: "bottom bottom",
      scrub: 1,
      once: true,
      invalidateOnRefresh: true,
      //markers: false,
      toggleClass: { targets: el, className: "active" },
    })
  })
  */

  setViewport()
  window.addEventListener('resize', setViewport)
}

function setViewport(){
  const vw = window.innerWidth;
  const vh = window.innerHeight * 0.01
  const head = document.getElementById('site-header')
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  document.documentElement.style.setProperty('--vw', `${vw}px`)
  if(head){
    const headSize = head.getBoundingClientRect().height
    document.documentElement.style.setProperty('--head', `${headSize}px`)
  }
}